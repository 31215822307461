import React, { useState, useContext, useMemo } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Badge from "@mui/material/Badge";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Link } from "react-router-dom";
import InfoIcon from '@mui/icons-material/Info';
import parse from 'html-react-parser';
import BedtimeOutlinedIcon from '@mui/icons-material/BedtimeOutlined';
import DriveEtaOutlinedIcon from '@mui/icons-material/DriveEtaOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CheckIcon from '@mui/icons-material/Check';
import Rating from "@mui/material/Rating";

// import "./bookingCard.css";
import SnackbarMsg from "../../Commons/snackbarMsg";
import { copyTextToClipboard } from "../../Utility";
import { PRIORITY_COLOR, PRIORITY_OPT } from "../../Constants";
import { MainContext } from "../../Utility";
import AgentRatingsView from "../../Commons/agentRatingsView";


const OthersReqCardIndex = ({ reqData = {}, itemId: id = "-" }) => {
  const [openSnack, setOpenSnack] = useState(false);
  const {
    agentId,
    destination,
    pax: pax,
    // childPax,
    noOfNights,
    cabType,
    pickUp,
    // dropOff,
    route = '',
    startDate,
    bids = [],
    trackingId,
    otherInfo,
    clientStatus,
  } = reqData;
  console.log("agent rating req_CHECK_PLS: ", reqData);
  const reqConfirmed = clientStatus?.flightsBooked || clientStatus?.hotelsBooked;
  const reqStatusLabel = `Client${clientStatus?.flightsBooked ? ' Flights ' : ''}${clientStatus?.hotelsBooked ? ' Hotels ' : ''} Booked`;
  const [expanded, setExpanded] = React.useState(true);
  const [compsOpen, setCompsOpen] = React.useState(false);
  const { isUserAgent } = useContext(MainContext);
  const userData = JSON.parse(localStorage.getItem("user"));
  const handleExpandClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCompsOpen(false);
    setExpanded(!expanded);
  };
  console.log("INNER_REQ_DATA_CHECK:", reqData, reqData?.totalBookings, bids, bids.length);

  const handleShowComponents = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setExpanded(false);
    setCompsOpen(!compsOpen)
  }

  const handleIdClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    copyTextToClipboard(`trackingId-${trackingId}`);
    setOpenSnack(true);
  };

  const lowerBid = useMemo(() => bids.reduce((lowest, b) => {
    let bidAmt = Number(b.bid);
    if (lowest == 0) lowest = bidAmt;
    if (bidAmt < lowest) lowest = bidAmt;
    return lowest;
  }, 0), [bids]);  //Math.min(...bids.map(req => parseInt(req.bid)))
  const isSupplierBid = !isUserAgent ? bids.find(i => i.supplierId == userData?.phone) : null
  console.log("agent rating lowerBid ", bids, lowerBid, bids.map(req => parseInt(req.bid)), isSupplierBid);
  const bidsCount = bids?.length?.toString();
  return (
    <>
      {openSnack && (
        <SnackbarMsg
          open={openSnack}
          message={"Req id copied!"}
          onClose={() => setOpenSnack(false)}
        />
      )}
      <span style={{ "display": "none" }} id={`trackingId-${trackingId}`}>{trackingId || "-"}</span>

      <Card sx={{ maxWidth: 545, margin: "auto", backgroundColor: '#fdfbee99' }}>
        <div style={{ display: "flex" }}>

          <div id="tracking-id" style={{ padding: "4px 0px 2px 8px", width: "60%", textAlign: "left" }}>
            {trackingId && (
              <Chip
                sx={{ cursor: "pointer", textAlign: "left", pl: 0.5 }}
                variant="outlined"
                size="small"
                className="singleline-ellipsis"
                label={<span id={trackingId}>{trackingId || "-"}</span>}
                icon={<ContentCopyIcon onClick={handleIdClick} fontSize="5px" />}
              />
            )}
          </div>
          <div style={{ width: "60%", marginRight: "3px", textAlign: "right" }}>
            <Chip
              variant="outlined"
              size="small"
              className="singleline-ellipsis"
              label={reqConfirmed ? reqStatusLabel : `Client Tickets Not Booked`}
              icon={reqConfirmed && <CheckIcon fontSize="8px" color={'yellowgreen'} />}
              sx={{
                textAlign: "right",
                fontSize: "9px",
                backgroundColor: reqConfirmed ? 'yellowgreen' : 'orange',
                color: "#000000"
              }}
            />
          </div>
        </div>
        <CardHeader
          sx={{ paddingBottom: 0, paddingTop: "3px" }}
          action={
            <Box sx={{ padding: 1 }}>
              <Badge
                badgeContent={bidsCount || "0"}
                showZero={true}
                color="primary"
              >
                <Typography variant="body1" color="text.primary"><b>Bids</b></Typography>

              </Badge>
              {
                bids?.length > 0 && (<>
                  {isUserAgent && <Typography sx={{ fontSize: "10px" }}>Best bid :<br /> <b>Rs. {lowerBid}</b></Typography>}
                  {isSupplierBid && <Typography sx={{ fontSize: "10px" }}>Your Bid:<br /> <b>Rs. {isSupplierBid?.bid}</b></Typography>}
                </>)
              }
            </Box>
          }
          title={
            <Box
              spacing={{ xs: 1, sm: 1 }}
              display="flex"
              flexDirection="column"

            >
              <Box display="flex" >
                <Box display="flex" sx={{ paddingRight: 3 }}>
                  <LocationOnIcon fontSize="small" />
                  <Typography sx={{ marginBottom: 1.2 }} variant="body2" color="text.primary">
                    <b>{destination}</b>
                  </Typography>
                </Box>
                <Box display="flex" sx={{ paddingRight: 3 }}
                >
                  <BedtimeOutlinedIcon fontSize="small" />
                  <Typography variant="body2" color="text.primary" >
                    {`${noOfNights} N`}
                  </Typography>
                </Box>
                <Box sx={{ paddingRight: 3 }} display="flex">
                  <PersonOutlineOutlinedIcon fontSize="small" />
                  <Typography variant="body2" color="text.primary">
                    {" "}{`  ${pax || 0}`}{" "}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" sx={{ paddingRight: 3 }}>
                <Box display="flex" flexDirection="row">
                  <DriveEtaOutlinedIcon fontSize="small" />
                  <Typography variant="body2" color="text.primary" sx={{ paddingRight: 3 }}>
                    {`${cabType} `}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="row" sx={{ paddingRight: 3 }}>
                  <LocationOnOutlinedIcon fontSize="small" />
                  <Typography variant="body2" color="text.primary" sx={{ paddingRight: 3 }}>
                    {`Pick Up: ${pickUp} `}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" sx={{ marginTop: 1 }}>
                <AccessTimeOutlinedIcon fontSize="small" />
                <Typography variant="body2" color="text.primary">
                  {`Trip Start: ${format(new Date(startDate), "dd-MMM-yyyy") || ""}`}
                </Typography>
              </Box>
              {/* {(!isUserAgent && totalBookings > 0) && (<Box display="flex" alignItems="center" marginTop={1}>
                {
                  overallRating && (<Rating
                    value={overallRating}
                    precision={0.5}
                    readOnly
                    size="small"
                  />)
                }
                <Typography variant="body2" color="secondary">
                  <small>Agent has ({totalBookings} Bookings on CabEasy)</small>
                </Typography>
              </Box>)} */}
              {(!isUserAgent) && <AgentRatingsView agentId={agentId} trackingId={reqData?.trackingId} />}
            </Box>
          }
          // subheader={
          //   <Stack
          //     divider={<Divider orientation="vertical" flexItem />}
          //     spacing={{ xs: 1, sm: 1 }}
          //     direction="row"
          //     useFlexGap
          //     flexWrap="wrap"
          //   >
          //     <Typography variant="body2" color="text.primary">
          //       <small>{pickUp?.label || ''}</small>
          //     </Typography>
          //     {/** <Typography variant="body2" color="text.primary">
          //         <small>{dropOff?.label || ''}</small>
          //       </Typography> **/}
          //     {/** <Typography className="singleline-ellipsis booking-card-subheader"
          //          variant="body2" color="text.primary"
          //       >
          //         <small>{cabType || ""}</small>
          //       </Typography> **/}
          //   </Stack>
          // }
        />

        <Divider sx={{ paddingTop: 1 }} />
        {/** <CardContent sx={{ paddingTop: "2px" }}>
            <Typography
              variant="body2"
              color="primary"
              className="multine-ellipsis"
              sx={{ paddingTop: "5px", textAlign: "center" }}
            >
              <b>{route || "-"}</b>
            </Typography>
          </CardContent> **/}

        <CardActions disableSpacing>
          {/* <Button size="small" variant="contained" onClick={handleShowComponents}
            endIcon={compsOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          >
            Route
          </Button> */}
          {otherInfo && (
            <Button
              variant="contained"
              size="small"
              sx={{ marginLeft: "auto", fontSize: 12 }}
              endIcon={expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              onClick={handleExpandClick}
            >
              Itinerary
            </Button>
          )}
        </CardActions>
        {/* <Collapse id="finalCompsOpts" in={compsOpen} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>
              <small>{parse(route)}</small>
            </Typography>
          </CardContent>
        </Collapse> */}

        <Collapse id="otherInfo" in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>
              <small>{parse(otherInfo)}</small>
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
};

export default OthersReqCardIndex;
