import Typography from "@mui/material/Typography";
// import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DestReqCardIndex from "../Commons/destReqCardIndex";
import AppLoader from "../layout/Loading";
import NoRecordsFound from "../Commons/NoRecordsFound.js";
import AppSubHeader from "../Commons/appSubHeader";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Chip from "@mui/material/Chip";
import ClearIcon from '@mui/icons-material/Clear';

import { REQ_STATUS_TAG, REQ_WAITING_STATUS_LABEL } from "../Constants.js";
import { MainContext, queryStringToObject } from "../Utility.js";

const SupplierView = ({
  userData,
  reqs,
  allReqs,
  setReqs,
  loading,
  getAllActiveReqs,
  getSuppAllReqs,
  setLoading
}) => {
  const [selectedDestination, setSelectedDestination] = useState("");
  const [filteredActiveReqs, setFilteredActiveReqs] = useState(reqs);
  const [filteredAllReqs, setFilteredAllReqs] = useState(allReqs);
  const searchParams = queryStringToObject(window.location.search);
  const [selectedTab, setSelectedTab] = useState( searchParams?.tab == "trackSupp" ? "all" : "active");
  const [showAll, setShowAll] = useState(false)

  const [activeDataLoaded, setActiveDataLoaded] = useState(false);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { userRating = {} } = useContext(MainContext);
  const prevPageReqs = allReqs[currentPage - 1];
  const navigate = useNavigate();

  useEffect(() => {
    const getReqs = async () => {
      if (selectedTab === "active" && !activeDataLoaded) {
        setLoading(true);
        try {
          await getAllActiveReqs();
          setActiveDataLoaded(true);
        } catch (error) {
          console.error("Error fetching active requirements:", error);
        } finally {
          setLoading(false);
        }
      } else if (selectedTab === "all" && !prevPageReqs) {
        setLoading(true);
        try {
          await getSuppAllReqs(currentPage);
          // if (!allReqs[currentPage]) {
          //   await getSuppAllReqs(currentPage + 1);
          // }
        } catch (error) {
          console.error("Error fetching all requirements:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    getReqs();
  }, [selectedTab, currentPage, activeDataLoaded, prevPageReqs]);

  const handleNextPage = async () => {
    console.log("getSuppAllReqs next", currentPage, allReqs[currentPage], )
    // if (!allReqs[currentPage]) {
    //   await getSuppAllReqs(currentPage + 1);
    // }
    // setCurrentPage((prev) => prev + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSelectedDestination(value);
    if(value.length < 3) return;
    if (selectedTab === "active") {
      setFilteredActiveReqs(
        reqs.filter((req) =>
          req.destination?.toLowerCase().includes(value)
        )
      );
    } else if (selectedTab === "all") {
      const filtered = allReqs.map((pageReqs) =>
        pageReqs.filter((req) =>
          req.destination?.toLowerCase().includes(value.toLowerCase())
        )
      );
      setFilteredAllReqs(filtered);
    }
    setShowAll(false);
  };

  const handleShowAllChange = (event) => {
    let newVal = true;  //!showAll;
    setShowAll(newVal);
    console.log("showall ", newVal)
    if (newVal) {
      setSelectedDestination(""); 
    }
  };

  useEffect(() => {
    if (selectedTab === "active") {
      if (showAll) {
        setFilteredActiveReqs(reqs);
      } else {
        setFilteredActiveReqs(
          reqs.filter((req) =>
            req.destination?.toLowerCase().includes(selectedDestination.toLowerCase())
          )
        );
      }
    } else if (selectedTab === "all") {
      const flattenedAllReqs = allReqs.flat();
      if (showAll) {
        setFilteredAllReqs(allReqs);
      } else {
        const filtered = allReqs.map((pageReqs) =>
          pageReqs.filter((req) =>
            req.destination?.toLowerCase().includes(selectedDestination.toLowerCase())
          )
        );
        setFilteredAllReqs(filtered);
      }
    }
  }, [reqs, allReqs, selectedTab, selectedDestination, showAll]);


  console.log("CHECK_DEST:", userData);
  console.log("CHECK_ALL_REQS:", allReqs);
  return (
    <>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginBottom={2}>
        <Tabs
          value={selectedTab}
          onChange={(event, newValue) => setSelectedTab(newValue)}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab value="active" label="Your Active Requirements" />
          <Tab value="all" label="Track Your Bids" />
        </Tabs>
        <div style={{ width: '100%', marginTop: '-8px' }}><hr /></div>
      </Box>
      
      {loading && <AppLoader />}

      {userData?.destinations?.length > 0 && (
        <Box display="flex" flexDirection="row" alignItems="flex-start" sx={{ marginTop: 1 }}>
          <Box display="flex" flexDirection="row" alignItems="flex-start" sx={{ marginY: 'auto' }}>
            <Typography variant="caption" sx={{ fontWeight: "bold" }}>
              Showing Requests for your Destinations:&nbsp;
            </Typography>
            <Typography variant="caption" sx={{ fontWeight: "bold"}}>
              <b>{userData.destinations.join(", ")} </b>
            </Typography>
          </Box>
          <Button
            variant="outlined"
            size="small"
            onClick={() => (window.location.href = "/profile")}
            sx={{
              fontSize: "14px",
              fontWeight: "bold",
              ml: 1
            }}
          >
            Edit Your Destinations
          </Button>
        </Box>

      )}

      <Box display="flex" flexDirection="row" sx={{ mb: 2 }}>
        <TextField
          size="small"
          label="Filter By Destination"
          onChange={handleSearchChange}
          variant="outlined"
          value={selectedDestination}
        />
        {/* <FormControlLabel
          sx={{ marginLeft: 1, marginTop: 1 }}
          control={<Checkbox checked={showAll} onChange={handleShowAllChange} />}
          label="Show All"
        /> */}
        <Button variant="text" size="small" onClick={handleShowAllChange}>Clear</Button>
      </Box>

      {selectedTab === "active" && (
        <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 2, sm: 8, md: 12 }}>
          {filteredActiveReqs.length <= 0 && !loading &&
            (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto"
                }}
              >
                <NoRecordsFound 
                  body={<>
                    <Typography variant="body1" sx={{ textAlign: "center", width: "100%", marginTop: 2 }}>
                      No Requirements matching your Destinations!
                    </Typography>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{
                        marginTop: "10px"
                      }}
                      onClick={() => navigate("/profile")}
                    >
                      Edit Your Destinations
                    </Button>
                  </>} 
                />
              </Box>

            )
          }
          {filteredActiveReqs.map((req) => {
            const { id = "", status = "", adminStatus = '' } = req;
            let finalStatus = REQ_STATUS_TAG[status]?.value;
            if(status == "active") finalStatus = adminStatus || REQ_WAITING_STATUS_LABEL;
            return (
              <Grid item xs={12} sm={6} md={6} key={id}>
                {status && (
                  <div style={{ textAlign: "left", marginRight: "10px" }}>
                    <Typography
                      style={{
                        display: "inline-block",
                        textAlign: "right",
                        backgroundColor: REQ_STATUS_TAG[status]?.color,
                        color: 'white',
                        borderTopLeftRadius: "5px",
                        borderTopRightRadius: "5px",
                        marginBottom: 0,
                        fontStyle: "italic",
                        padding: "2px",
                        paddingLeft: "5px",
                        paddingRight: "7px",
                        boxShadow: "0px -4px 6px -4px rgba(0, 0, 0, 0.1)",
                        fontSize: "12px",
                      }}
                    >
                      {finalStatus}
                    </Typography>
                  </div>
                )}
                <Link to={`/req/post-bid/${id}`} state={{ req }}>
                  <DestReqCardIndex reqData={req} itemId={id} />
                </Link>
              </Grid>
            );
          })}
        </Grid>
      )}

      {selectedTab === "all" && (
        // <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 2, sm: 8, md: 12 }}>
        <Box>
          {filteredAllReqs.length <= 0 && !loading && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto"
              }}
            >
              <NoRecordsFound 
                body={<>
                  <Typography variant="body1">You have posted 0 Bids.</Typography>
                  <Typography variant="caption">Post your First Bid and start the Journey. Happy Bookings!</Typography>
                </>} 
              />
              
              {/* <Typography variant="h6" sx={{ textAlign: "center", width: "100%" }}>
                Check Destinations in Profile
              </Typography>
              <Button
                variant="contained"
                sx={{
                  marginTop: "10px"
                }}
                onClick={() => window.location.href = "/profile"}
              >
                Click Here
              </Button> */}
            </Box>
          )}

          <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 2, sm: 8, md: 12 }} sx={{ mt: 1 }}>
            {(filteredAllReqs[currentPage - 1] || []).map((req) => {
              const { id = "", status = "", adminStatus = '' } = req;
              let finalStatus = REQ_STATUS_TAG[status]?.value;
              if(status == "active") finalStatus = adminStatus || REQ_WAITING_STATUS_LABEL;
              return (
                <Grid item xs={12} sm={6} md={6} key={id}>
                  {status && (
                    <div style={{ textAlign: "left", marginRight: "10px" }}>
                      <Typography
                        style={{
                          display: "inline-block",
                          textAlign: "right",
                          backgroundColor: REQ_STATUS_TAG[status]?.color,
                          color: 'white',
                          borderTopLeftRadius: "5px",
                          borderTopRightRadius: "5px",
                          marginBottom: 0,
                          fontStyle: "italic",
                          padding: "2px",
                          paddingLeft: "5px",
                          paddingRight: "7px",
                          boxShadow: "0px -4px 6px -4px rgba(0, 0, 0, 0.1)",
                          fontSize: "12px",
                        }}
                      >
                        {finalStatus}
                      </Typography>
                    </div>
                  )}
                  <Link to={`/req/post-bid/${id}`} state={{ req }}>
                    <DestReqCardIndex reqData={req} itemId={id} />
                  </Link>
                </Grid>
              );
            })}

            
          </Grid>
          <Box display="flex" justifyContent="center" marginTop={3}>
            <Button
              variant="contained"
              disabled={currentPage === 1}
              onClick={handlePrevPage}
              sx={{ marginRight: 2 }}
            >
              Prev
            </Button>
            <Button
              variant="contained"
              onClick={handleNextPage}
              disabled={(filteredAllReqs[currentPage] || []).length === 0}
            >
              Next
            </Button>
          </Box>
          {/* </Grid> */}
        </Box>
      )}

    </>
  );
};

export default SupplierView;
