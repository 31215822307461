import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import useMediaQuery from "@mui/material/useMediaQuery";
import { doc, getDoc, setDoc,collection } from "firebase/firestore";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

import SnackbarMsg from "../Commons/snackbarMsg";
import { countryCodes } from "../Commons/Country";
import LoadingButton from "../Commons/LoadingButton";
import CopyrightFooter from "../Commons/copyright-footer";
import { auth, db } from "../firebaseConfig";
import LoadingView from "../loading";
import CabEasyLogo from "../utills/logoImgBox";
import MenuItem from '@mui/material/MenuItem';
import Select from "@mui/material/Select";
import { set } from "date-fns";
import { OFFICIAL_WA_LINK } from "../Constants.js";
import { useUrlParams } from "../Utility.js";

export default function Login() {
  // Inputs
  const [mynumber, setnumber] = useState("");
  const [otp, setotp] = useState("");
  const [showOtpView, setShowOtpView] = useState(false);
  const [isLoginError, setLoginError] = useState(false);

  const [final, setfinal] = useState(null);
  const [userType, setUserType] = React.useState("agent");
  const [optVerified, setOptVerified] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [hideGetOtpBtn, setHideOtpButton] = useState(false);
  const { state = {} } = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const urlParamsData = useUrlParams();
  // const signInConfirm = useRef(null);

  const { loginRedirectUrl = "/home" } = state || {};
  const userData = JSON.parse(localStorage.getItem("user") || null);
  const isLoggedIn = useRef(Boolean(userData));
  const [code, setCode] = useState('+91');


  useEffect(() => {
    console.log("isLoggedIn Login", isLoggedIn.current)
    if (isLoggedIn.current) navigate("/home");
  }, [isLoggedIn.current])

  useEffect(() => {
    console.log("login prefill ", urlParamsData.get('phone'));
    if(urlParamsData.get('phone')) setnumber(urlParamsData.get('phone'));
    if(urlParamsData.get('code')) setCode(`+${urlParamsData.get('code')}`);
  }, [urlParamsData])

  // Sent OTP
  const signin = async () => {
    if (mynumber === "" || mynumber.length < 10) return;
    const finalPhone = `${code}${mynumber}`;
    setLoading(true);
    let verify = null;
    try {
      verify = new RecaptchaVerifier("recaptcha-container", {}, auth);
    } catch {
      verify = new RecaptchaVerifier(auth, "recaptcha-container", {});
    }
    // let verify  = new RecaptchaVerifier("recaptcha-container", {}, auth);
    setHideOtpButton(true);
    console.log("signin captcha initialize ", auth, finalPhone, verify);
    signInWithPhoneNumber(auth, finalPhone, verify)
      .then((result) => {
        console.log("signin captcha result ", result);
        // signInConfirm.current = result
        setfinal(result);
        result && setLoading(false);
        // alert("code sent")
        setShowOtpView(true);
      })
      .catch((err) => {
        console.log("signin captcha error ", err);
        alert(err);
        setLoading(false);
        //reset captcha
        // window.recaptchaVerifier.render().then(function(widgetId) {
        //   grecaptcha.reset(widgetId);
        // });
        window.location.reload();
      });
  };

  const saveUserDetails = async () => {
    console.log("save user ---- ", code);
    const docSnap = await getDoc(doc(db, "userDetails", `${code}${mynumber}`));
    // docSnap, docSnap.exists(),
    if (docSnap.exists()) {
      console.log("save user data ", docSnap.data());
      let localSessionData = { ...docSnap.data() }
      localSessionData["lastLoginTime"] = Date.now();
      delete localSessionData["adminRemarks"];
      delete localSessionData["kycDocs"];
      localStorage.setItem("user", JSON.stringify(localSessionData));
      let docRef = doc(collection(db, "userDetails"), `${code}${mynumber}`);
      await setDoc(docRef, { "lastLoginTime": localSessionData["lastLoginTime"] }, { merge: true });  
    } else {
      console.log("doc doesnt exist..redirecting to signup");
      navigate("/signup");
    }
  };
  // Validate OTP
  const validateOtp = () => {
    if (otp === null || final === null) return;
    console.log("valid otp", otp, final);
    setLoading(true);
    final
      .confirm(otp)
      .then(async (result) => {
        console.log("otp success", result, result._tokenResponse);
        await saveUserDetails();
        setTimeout(() => {
          //   // setOptVerified(true);
          setLoading(false);
          navigate(loginRedirectUrl || "/home");
        }, 2000);
      })
      .catch((err) => {
        console.log("otp error catch ", err);
        setLoginError(true);
        // TODO: use snackbar for error msg
        setLoading(false);
      });
  };

  if (!auth) return <LoadingView />;
  console.log("login render- ", code, mynumber);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Login | Book now and get amezing discount on your next booking -
          CabEasy
        </title>
        <meta
          name="description"
          content="Login to CabEasy and take advantage of our exclusive first-time booking offer. Book now and enjoy amazing discounts on your next ride. Get verified B2B cab quotes within 15 minutes for destinations like Himachal, Kashmir, Rajasthan, Kerala, Karnataka, Tamil Nadu, Uttarakhand, and Andaman and Nicobar Islands. Visit cabeasy.in and experience seamless b2b cab bookings with unbeatable offers."
        />
        <link rel="canonical" href="https://cabeasy.in/login" />
      </Helmet>

      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <SnackbarMsg
          open={isLoginError}
          message={"OTP is not correct."}
          severity="error"
        />
        <Grid
          item
          xs={false}
          sm={5}
          md={8}
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/cabeasy-db.appspot.com/o/website%2Floginpage.png?alt=media&token=8209e787-c837-4cc3-ada7-4318c2b7aa6c"
            height="100%" width="100%"
            style={{
              objectFit: "cover",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} elevation={7} square component={isMobile ? '' : Paper}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CabEasyLogo />
            <Typography
              component="h5"
              variant="h5"
              style={{
                fontSize: 15,
                marginTop: "1em",
                fontWeight: "600",
              }}
            >
              {!showOtpView ? "Sign in" : "OTP Verification"}
            </Typography>
            <Box sx={{ mt: 5, my: 8, minWidth: 300 }}>
              <div style={{ display: !showOtpView ? "block" : "none" }}>
                <Box sx={{ display: "flex" }}>
                  <Select
                    value={ code }
                    label="Country Code"
                    onChange={e => setCode(e.target.value)}
                    sx={{
                      border: 'none',
                      outline: 'none',
                      justifyContent: "left"
                    }}
                  >
                    {countryCodes.map((country) => (
                      <MenuItem key={country.code} value={country.code}>
                        {country.code}&nbsp;{country.country}
                      </MenuItem>
                    ))}
                  </Select>
                  <TextField
                    id="phone"
                    label="Phone"
                    fullWidth
                    placeholder="1234567890"
                    variant="outlined"
                    onChange={(e) => setnumber(e.target.value.trim())}
                    autoFocus
                    value={mynumber}
                  />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <div id="recaptcha-container"></div>
                </Box>
                <LoadingButton
                  loading={loading}
                  type="submit"
                  sx={{ mt: 4, mb: 2 }}
                  onClick={signin}
                >
                  Get Otp
                </LoadingButton>
              </div>

              <div style={{ display: showOtpView ? "block" : "none" }}>
                <TextField
                  id="otp"
                  label="OTP"
                  fullWidth
                  placeholder="Enter your OTP"
                  variant="outlined"
                  onChange={(e) => {
                    setotp(e.target.value);
                    setLoginError(false);
                  }}
                />

                <LoadingButton
                  loading={loading}
                  type="submit"
                  sx={{ mt: 4, mb: 2 }}
                  onClick={validateOtp}
                >
                  Verify & Proceed
                </LoadingButton>
              </div>
              <Grid justifyContent="center" sx={{ mt: 6 }}>
                <Grid item>
                  <Link
                    href="/signup"
                    variant="body2"
                    sx={{
                      textAlign: "center",
                      color: "#000",
                      fontWeight: "bold",
                    }}
                  >
                    {"Don't have an account?"}
                    <>&nbsp;</>{" "}
                    <span style={{ fontWeight: "bold" }}>Sign Up</span>
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    href={OFFICIAL_WA_LINK}
                    variant="body2"
                    sx={{
                      textAlign: "center",
                      color: "#000",
                      fontWeight: "bold",
                    }}
                  >
                    {"Talk to us directly:"}
                    <>&nbsp;</>{" "}
                    <span style={{ fontWeight: "bold" }}>Connect on WhatsApp</span>
                  </Link>
                </Grid>
              </Grid>
              <CopyrightFooter sx={{ mt: 15 }} />
            </Box>
          </Box>
        </Grid>
        {optVerified && <Navigate to="/home" />}
      </Grid>
    </>
  );
}
