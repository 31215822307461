/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import React, { useEffect, useMemo, useState } from "react";
import { doc, onSnapshot, getDoc } from "firebase/firestore";

import { useNavigate, useLocation } from "react-router-dom";
import FullScreenAppPopup from "../Commons/fullScreenAppPopup";
import MyDailog from "../Commons/myDailog.jsx";
import Navbar from "../Navbar";
import { MainContext, devConsolelog, calcUserRatings } from "../Utility";
import KycPending from '../SignUp/kycPending';
import { auth, db } from "../firebaseConfig.js";
import SnackbarMsg from "../Commons/snackbarMsg";
import { AGENT_RATING_LEVELS, SUPP_RATING_LEVELS } from "../Constants.js";
import SuppLevelView from "../SuppLevelView/index.js";

const RedirectComponent = () => {
  let location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // window.location = "/login";
    navigate("/login", { state: { loginRedirectUrl: location.pathname } });
  }, []);
};

function PrivateRoute({ children, authed = false, props }) {
  if (typeof window !== "undefined") {
    console.log("PrivateRoute - ", props, authed);
    if (authed) return <>{children}</>;
    else return <RedirectComponent />;
  } else {
    return null;
  }
}

// check for the last day when kyc pop up was shown, show only once a day. 
const lastKycShowTime = localStorage.getItem("kycPopUpTime");
const checkShowKycPop = (new Date(Date.now()).getDate() - new Date(Number(lastKycShowTime || Date.now())).getDate()) >= 1;
if (lastKycShowTime === null) localStorage.setItem("kycPopUpTime", Date.now());
console.log("checkShowKycPop", checkShowKycPop, new Date(Date.now()).getDate(), new Date(Number(localStorage.getItem("kycPopUpTime") || Date.now())).getDate());

const AppLayout = ({ children, showNavBar = true }) => {
  const userData = JSON.parse(localStorage.getItem("user"));
  const isUserAgent = Boolean(userData?.isAgent);
  const isLoggedIn = Boolean(userData); // !isEmptyObject(userData);
  const [showSnackbar, setShowSnackbar] = useState({ open: false });
  const [showAgentKyc, setAgentKyc] = useState(checkShowKycPop);
  const [userRating, setUserRating] = useState({ rating: 0 });
  // let userRating = { rating: 0 };
  let feedBackRatings = userData?.ratings || {}
  devConsolelog("AppLayout render ", showAgentKyc)
  // const IS_KYC_POPUP_SKIPPED =
  //   localStorage.getItem("kyc-popup-skip") === "true" || false;

  useEffect(() => {
    let { phone = '' } = userData || {};
    if (!phone) {
      console.warn("Phone number is not available in userData.");
      return;
    }

    const userDocRef = doc(db, "userDetails", phone);
    const unsubscribe = onSnapshot(
      userDocRef,
      (doc) => {
        if (!doc.exists()) {
          // window.alert("No matching documents.");
          return;
        }

        const newUserData = doc.data();
        console.log("user kyc ", phone, newUserData.isKycVerified, userData?.isKycVerified);
        if (newUserData.isKycVerified != userData?.isKycVerified) {
          delete newUserData["adminRemarks"];
          delete newUserData["kycDocs"];
          localStorage.setItem('user', JSON.stringify(newUserData));
          if (newUserData.isKycVerified) setShowSnackbar({
            message: "Your Account is now Verified!",
            open: true,
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
        }

        fetchUserIdToken();
        // fetchUserStats(phone);
      },
      (error) => {
        console.error("Error fetching activities:", error);
      }
    );

    return () => unsubscribe();
  }, [userData]);

  const fetchUserIdToken = async () => {
    try {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          // user.getIdToken().then(function(data) {
          //   console.log(data)
          // });
          let signedInIdToken = await auth.currentUser.getIdToken(
            /* forceRefresh */ true,
          );
          console.log("signedInIdToken ", signedInIdToken, typeof signedInIdToken);
          localStorage.setItem('user', JSON.stringify({ ...userData, firebaseIdToken: signedInIdToken }));
          userData['firebaseIdToken'] = signedInIdToken;
        }
      });
    } catch (e) {
      console.log("signedInIdToken error ", e);
    }
  };

  useEffect(() => {
    if (!userData?.phone) return;
    fetchUserStats(userData?.phone);
  }, [userData?.phone])

  const contextValue = useMemo(
    () => ({
      userData,
      isUserAgent,
      isLoggedIn,
      showKycPopup: setAgentKyc,
      userRating
    }),
    [userData, isUserAgent, isLoggedIn, userRating]
  );

  const handleClose = () => {
    devConsolelog("close kyc popup", showAgentKyc, checkShowKycPop);
    setAgentKyc(false);
  };

  const fetchUserStats = async (userPhone) => {
    console.log("supp rating level start ", userPhone, isUserAgent)
    try {
      // const userDetailsRef = doc(db, "userDetails", userPhone);
      // const userDetailsSnap = await getDoc(userDetailsRef);
      // if (userDetailsSnap.exists()) {
      //   setOverallRating(userDetailsSnap.data()?.ratings?.overallRating || null);
      // }

      const userStatisticsRef = doc(db, "userStatistics", userPhone);
      const userStatisticsSnap = await getDoc(userStatisticsRef);
      if (!userStatisticsSnap.exists()) return;
      let statsData = userStatisticsSnap.data();
      let ratingLevel = calcUserRatings({ isUserAgent, statsData });
      setUserRating({
        rating: ratingLevel?.stars || 0,
        ratingLabel: ratingLevel?.label,
        commissionRate: ratingLevel?.commissionRate,
        ...statsData
      })

      // if(isUserAgent) {
      //   // calc Agent Rating
      //   const score = Number(statsData?.totalBookings || 0) / Number(statsData?.totalReqsPosted || 0);
      //   console.log("agent rating score ", score, statsData, userPhone);
      //   let ratingLevel = AGENT_RATING_LEVELS.find((i) => {
      //     return score > Number(i.minPoints) && score <= Number(i.maxPoints)
      //   });
      //   ratingLevel = ratingLevel || {};
      //   console.log("agent rating level ", ratingLevel, userPhone);
      //   // userRating = {
      //   //   rating: ratingLevel?.stars || 0,
      //   //   ratingLabel: ratingLevel?.label,
      //   //   ...statsData
      //   // }
      //   setUserRating({
      //     rating: ratingLevel?.stars || 0,
      //     ratingLabel: ratingLevel?.label,
      //     ...statsData
      //   })
      // } else {
      //   // calc Supp Rating
      //   let ratingLevel = calcUserRatings({isUserAgent, statsData});
      //   // let suppBookings = statsData?.totalBookings || 0;
      //   // let ratingLevel = SUPP_RATING_LEVELS.find((i) => {
      //   //   return suppBookings > Number(i.minPoints) && suppBookings <= Number(i.maxPoints)
      //   // });
      //   // ratingLevel = ratingLevel || {};
      //   console.log("supp rating level ", ratingLevel, userPhone);
      //   // userRating = {
      //   //   rating: ratingLevel?.stars || 0,
      //   //   ratingLabel: ratingLevel?.label,
      //   //   ...statsData
      //   // }
      //   setUserRating({
      //     rating: ratingLevel?.stars || 0, 
      //     ratingLabel: ratingLevel?.label,
      //     ...statsData
      //   })
      // }
      // setTotalBookings(statsData?.totalBookings || 0);
    } catch (error) {
      console.error("Error fetching agent rating:", error);
    }
  };

  useEffect(() => {
    if (!Boolean(userData)) return;
    console.log('show kyc popup 1', showAgentKyc, checkShowKycPop, !userData?.isKycVerified);
    // if (!isUserAgent && !userData.isKycVerified)
    //   navigate("/kyc-pending");
    // else 
    if (!userData.isKycVerified && showAgentKyc) {
      setAgentKyc(showAgentKyc);
      // if(checkShowKycPop) {
      try {
        localStorage.setItem("kycPopUpTime", Date.now());
      } catch (e) {
        console.log("localStorage kycPopUpTime error, e")
      }
      // }
    }

    //get Stats


  }, [userData]);

  console.log('layout render ', userData, isLoggedIn, userRating);
  return (<>
    {/** (<FullScreenAppPopup handleClose={handleClose} open={showAgentKyc} />) **/}

    <PrivateRoute authed={isLoggedIn}>
      <MainContext.Provider value={contextValue}>
        <div id="myDiv" className="animate-bottom">
          {showNavBar && <Navbar />}
          <Container maxWidth="md" fixed>
            <Box component="main" sx={{ pt: 9, pb: 5 }}>
              {children}
            </Box>
          </Container>
        </div>
      </MainContext.Provider>
    </PrivateRoute>
    {showAgentKyc && isUserAgent && <MyDailog showDailog={showAgentKyc} body={<KycPending />} handleClose={handleClose} />}
    {showAgentKyc && !isUserAgent && userRating && <SuppLevelView open={showAgentKyc} userRating={userRating} onClose={handleClose} />}    {showSnackbar && (
      <SnackbarMsg
        open={showSnackbar.open}
        message={showSnackbar.message}
        anchorOrigin={showSnackbar.anchorOrigin}
        severity={showSnackbar.severity || "success"}
        onClose={() => setShowSnackbar({ open: false })}
      />
    )}
  </>);
};

export default AppLayout;
