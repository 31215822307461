import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Checkbox from "@mui/material/Checkbox";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import React, { useState, useEffect, useRef } from "react";
import { nanoid } from "nanoid";
import useFileUpload from "react-use-file-upload";
import { Helmet } from "react-helmet";

import LoadingButton from "../Commons/LoadingButton";
import SnackbarMsg from "../Commons/snackbarMsg";
import { DestinationNames } from "../Constants";
import {
  AutocompleteInput,
  isEmptyObject,
  devConsolelog,
  getUserDetails,
  getBase64,
} from "../Utility";
import { db } from "../firebaseConfig";
import "./profile.css";

const randomProfilePic = "https://xsgames.co/randomusers/avatar.php?g=pixel";

export default function UserProfile() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const [profileDetails, setProfilDetails] = useState(userData);
  const [agencyToggle, setAgencyToggle] = useState(true);
  const [showMsg, setShowMsg] = useState(false);
  const [otherServicesData, setOtherServicesData] = useState({});
  const [fileUploaded, setFileUploaded] = useState(false);
  const inputRef = useRef();
  const storage = getStorage();

  const {
    name = "",
    email = "",
    phone = "",
    destinations = [],
    companyDetails: {
      companyName = "",
      companyEmail = "",
      website = "",
      logo = "",
    } = {},
    otherServices = [],
  } = profileDetails;

  const { files, setFiles } = useFileUpload();

  useEffect(() => {
    const getData = async () => {
      let userDataDb = await getUserDetails(userData.phone);
      console.log("userDataDb ", userDataDb);
      if (userDataDb) setProfilDetails(userDataDb);
      return userDataDb;
    };

    console.log("check userDataDb", userData);
    if (userData) getData();
    const obj = {};
    otherServices.forEach((value, index) => obj[value] = true);
    console.log("set otherServices", otherServices, obj)
    setOtherServicesData(obj);
  }, []);

  useEffect(() => {
    if (fileUploaded) {
      handleSend("file");
    }
  }, [fileUploaded]);

  const handleSend = async (type = "text") => {
    const docRef = doc(db, "userDetails", userData.phone);
    devConsolelog("handleSend ===> ", type, files);
    //   if (type === "file") {
    let dbFileUrls = files.map(async (file, index) => {
      let dbFileName = `${nanoid(5)}_logo_${userData.phone}_${
        files[index]?.name
      }`;
      const storageRef = ref(storage, `user-docs/${dbFileName}`);
      try {
        let snapshot = await uploadBytes(storageRef, file);
      } catch (e) {
        console.error("handle send upload error!! ", e);
        // Todo: handle upload error msg view
        return;
      }
      // await handleSavePdfData(file);
      let url = await getDownloadURL(storageRef);
      return {
        name: files[index]?.name,
        url,
      };
    });
    devConsolelog("handleSend files", dbFileUrls);
    let finalDbData = null;
    Promise.all(dbFileUrls)
      .then(async (data) => {
        devConsolelog("handleSend db file urls", data);
        let finalData = data.map((item) => {
          devConsolelog("handleSend db file url item", item, item.url);
          return item.url;
        });

        let updatedProfileData = {
          companyDetails: {
            ...userData.companyDetails,
            logo: finalData[0],
          },
        }
        await updateDoc(docRef, updatedProfileData);
        setProfilDetails(updatedProfileData);
      })
      .catch((e) => console.log("logo file error -", e));
  };

  // const handleSavePdfData = async (companyLogo) => {
  //   // setCompanyInfo({ ...companyInfo, companyLogo });
  //   let fileB64 = await getBase64(companyLogo);
  //   console.log("fileB64 ", fileB64, companyLogo);
  //   let docRef = doc(db, "userPdfData", userData.phone)
  //   await setDoc(docRef, {
  //     logoB64Str: fileB64
  //   }, { merge: true });
  // }

  const handleFormChange = (name, value) => {
    let profileState = { ...profileDetails };
    if (name === "destinations") {
      const destVals = typeof value === "string" ? value.split(",") : value;




      let destData = {};
      destVals.forEach((item, i) => {
        destData[item] = true;
      });
      console.log(destData, destVals, "destData", Object.keys(destData));
      profileState[name] = Object.keys(destData);
    } else {
      profileState[name] = value;
    }
    setProfilDetails(profileState);
  };

  const handleOtherServicesChange = (event) => {
    console.log("handleOtherServicesChange ", event.target.name, event.target.checked, otherServicesData)
    let updatedServices = {
      ...otherServicesData,
      [event.target.name]: event.target.checked,
    };
    console.log("handleOtherServicesChange final ", updatedServices)
    setOtherServicesData(updatedServices);
    // let tempData = { ...profileDetails };
    // tempData["otherServices"] = updatedServices;
    // setProfilDetails(tempData);
  };

  const handleUpdateProfile = async () => {
    const docRef = doc(db, "userDetails", userData.phone);
    let tempData = { ...profileDetails };

    if (!isEmptyObject(otherServicesData)) {
      let finalOtherServies = Object.keys(otherServicesData).filter(
        (i) => otherServicesData[i],
      );
      tempData["otherServices"] = finalOtherServies;
    } else tempData["otherServices"] = [];
    await updateDoc(docRef, tempData);
    setShowMsg(true);
    localStorage.setItem("user", JSON.stringify(tempData));
  };

  console.log("profile render ", otherServicesData, otherServicesData["Sightseeing"]);
  return (
    <>
      <Helmet>
        <title>CabEasy My Profile: Check and Update your Profile Details</title>
        <link rel="canonical" href="https://cabeasy.in/accounts" />
      </Helmet>
      {showMsg && (
        <SnackbarMsg
          open={showMsg}
          severity="success"
          message={"Profile updated!"}
          onClose={() => setShowMsg(false)}
        />
      )}
      <div className="container">
        <div id="content" className="content p-0">
          <div className="profile-header">
            <div className="profile-header-cover" />
            <div className="profile-header-content" style={{ display: "flex" }}>
              <Box display="flex" flexDirection="column">
                <div className="profile-header-img mb-4" style={{ "display": "contents"}}>
                  <img
                    src={logo ? logo : randomProfilePic}
                    className="mb-4"
                    alt=""
                    style={{
                      height: "inherit",
                      width: "inherit",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div style={{}}>
                  <Box>
                    {/** <IconButton
                                          color="primary"
                                          sx={{ pl: "0px" }}
                                          aria-label="directions"
                                          onClick={() => inputRef.current.click()}
                                          variant="contained"
                                        >
                                          <UploadFileIcon />
                                          <Typography variant="caption">Upload Logo</Typography>
                                        </IconButton>
                                        <Typography variant="caption">Upload Logo</Typography>
                    **/}
                    <Button variant="contained" startIcon={<UploadFileIcon />} size="small" aria-label="upload-logo"
                      onClick={() => inputRef.current.click()}
                    >Upload Logo</Button>
                    <input
                      ref={inputRef}
                      type="file"
                      multiple
                      style={{ display: "none" }}
                      onChange={(e) => {
                        setFiles(e, "a");
                        inputRef.current.value = null;
                        setFileUploaded(true);
                      }}
                    />
                  </Box>
                </div>
              </Box>
              <div
                className="profile-header-info"
                style={{ flexGrow: "1", marginLeft: 8 }}
              >
                <span className="m-t-xs">{userData?.name}</span>
                <p className="m-t-xs">
                  {userData?.isSupplier ? "Supplier" : "Agent"}
                </p>
              </div>
            </div>
            {/* <ul className="profile-header-tab nav nav-tabs">
              <li className="nav-item">
                <a href="#profile-post" className="nav-link" data-toggle="tab">
                  ABOUT
                </a>
              </li>
              <li className="nav-item">
                <a href="#profile-about" className="nav-link" data-toggle="tab">
                  {" "}
                  BOOKINGS
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#profile-photos"
                  className="nav-link"
                  data-toggle="tab"
                >
                  REVIEWS
                </a>
              </li>
            </ul> */}
          </div>
          <div className="profile-container">
            <div className="row row-space-20">
              <div className="col-md-12">
                <ul className="profile-info-list">
                  <li className="title">PERSONAL INFORMATION</li>
                  <li>
                    <div className="field">Name</div>
                    <div className="value">
                      <TextField
                        fullWidth
                        id="name"
                        variant="outlined"
                        value={profileDetails.name}
                        onChange={(e) =>
                          handleFormChange("name", e.target.value)
                        }
                      />
                    </div>
                  </li>
                  <li>
                    <div className="field">Mobile Number</div>
                    <TextField
                      fullWidth
                      id="mobile"
                      variant="outlined"
                      value={userData?.phone}
                      disabled
                    />
                  </li>
                  <li>
                    <div className="field">Email:</div>
                    <div className="value">
                      <TextField
                        fullWidth
                        id="email"
                        variant="outlined"
                        value={profileDetails.email}
                        onChange={(e) =>
                          handleFormChange("email", e.target.value)
                        }
                      />
                    </div>
                  </li>
                  {userData?.isSupplier && (
                    <li>
                      <div className="field">Desitnations</div>
                      <div className="value">
                          <AutocompleteInput
                            label=""
                            onChange={(e) =>
                              handleFormChange("destinations", e.target.value)
                            }
                            value={profileDetails["destinations"] || []}
                            multiSelect={true}
                            values={DestinationNames}
                          />

                        {/** <AutocompleteInput
                                                  label=""
                                                  onChange={(e) =>
                                                    handleFormChange("destinations", e.target.value)
                                                  }
                                                  value={destinations || []}
                                                  multiSelect={true}
                                                  values={DestinationNames}
                                                  input={<OutlinedInput label="Tag" />}
                                                  renderValue={(selected) => selected.join(', ')}
                                                /> **/}
                      </div>
                    </li>
                  )}
                  <br />
                  <ul className="profile-info-list">
                    {/** <LoadingButton
                      loading={false}
                      fullWidth={false}
                      onClick={() => setAgencyToggle(!agencyToggle)}
                      sx={{ mt: 3, mb: 2 }}
                    >
                     {`${agencyToggle ? "Hide" : "Update"}`} Agency Details
                    </LoadingButton> **/}
                    <li className="title">Agency Details</li>
                  </ul>
                  <Box sx={{ display: agencyToggle ? "block" : "none" }}>
                    <ul className="profile-info-list">
                      <li>
                        <div className="field">Agency Name</div>
                        <div className="value">
                          <TextField
                            fullWidth
                            id="agencyName"
                            variant="outlined"
                            value={companyName}
                            onChange={(e) =>
                              setProfilDetails((prevState) => ({
                                ...prevState,
                                companyDetails: {
                                  ...prevState.companyDetails,
                                  companyName: e.target.value,
                                },
                              }))
                            }
                          />
                        </div>
                      </li>
                      <li>
                        <div className="field">Website</div>
                        <TextField
                          fullWidth
                          id="website"
                          variant="outlined"
                          value={website}
                          onChange={(e) =>
                            setProfilDetails((prevState) => ({
                              ...prevState,
                              companyDetails: {
                                ...prevState.companyDetails,
                                website: e.target.value,
                              },
                            }))
                          }
                        />
                      </li>
                      <li>
                        <div className="field">Agency Email:</div>
                        <div className="value">
                          <TextField
                            fullWidth
                            id="agencyEmail"
                            variant="outlined"
                            value={companyEmail}
                            onChange={(e) =>
                              setProfilDetails((prevState) => ({
                                ...prevState,
                                companyDetails: {
                                  ...prevState.companyDetails,
                                  companyEmail: e.target.value,
                                },
                              }))
                            }
                          />
                        </div>
                      </li>
                    </ul>

                    <br />
                    <ul className="profile-info-list">
                      {/** <LoadingButton
                        loading={false}
                        fullWidth={false}
                        onClick={() => setAgencyToggle(!agencyToggle)}
                        sx={{ mt: 3, mb: 2 }}
                      >
                       {`${agencyToggle ? "Hide" : "Update"}`} Agency Details
                      </LoadingButton> **/}
                      <li className="title">Other Services</li>
                    </ul>
                    <Box sx={{ display: agencyToggle ? "block" : "none" }}>
                          <FormControl
                            sx={{ m: 1 }}
                            component="fieldset"
                            variant="standard"
                          >
                            <FormLabel component="legend">
                              Your Other Services? (We will promote these
                              services after every booking)
                            </FormLabel>
                            <FormGroup>

                              <OtherServCheckbox name="Sightseeing" checked={otherServicesData["Sightseeing"]} handleOtherServicesChange={handleOtherServicesChange} />
                              <OtherServCheckbox name="Packages" checked={otherServicesData["Packages"]} handleOtherServicesChange={handleOtherServicesChange} />
                              <OtherServCheckbox name="Hotels" checked={otherServicesData["Hotels"]} handleOtherServicesChange={handleOtherServicesChange} />
                              {/** <FormControlLabel
                                                              control={
                                                                <Checkbox
                                                                  defaultChecked={otherServicesData["Sightseeing"]}
                                                                  checked={otherServicesData["Sightseeing"]}
                                                                  onChange={handleOtherServicesChange}
                                                                  name="Sightseeing"
                                                                />
                                                              }
                                                              label="Sightseeing"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Checkbox
                                                                  checked={otherServicesData["Packages"]}
                                                                  onChange={handleOtherServicesChange}
                                                                  name="Packages"
                                                                />
                                                              }
                                                              label="Packages"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Checkbox
                                                                  checked={otherServicesData["Hotels"]}
                                                                  onChange={handleOtherServicesChange}
                                                                  name="Hotels"
                                                                />
                                                              }
                                                              label="Hotels"
                                                            />  **/}
                            </FormGroup>
                            <FormHelperText></FormHelperText>
                          </FormControl>
                    </Box>
                  </Box>
                  <li>
                    <LoadingButton
                      loading={false}
                      onClick={handleUpdateProfile}
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Update Profile
                    </LoadingButton>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const OtherServCheckbox = ({name, checked = false, handleOtherServicesChange}) => {
  return (<FormControlLabel
    control={
      <Checkbox
        defaultChecked={checked}
        checked={checked}
        onChange={handleOtherServicesChange}
        name={name}
      />
    }
    label={name}
  />)
}