import React from "react";

const NO_DATA_IMG = require('./no_data_found.png');

const defaultMsg = "No Records Found.";

const NoRecordsFound = ({message = '', body = null}) => {
  return (
    <>
    <center>
      <img src={NO_DATA_IMG} alt="no_data" style={{maxWidth:'20%'}}></img>

      {
        message && (<p style={{ margin: "0.5em auto", opacity:"0.7" }}>
          <b>{message}</b>
        </p>)
      }
      {body}
    </center>
  </>
  );
};

NoRecordsFound.defaultProps = {
  message: defaultMsg,
};

export default NoRecordsFound;
