import React, { useState, useEffect, useContext } from "react";
import {
  doc,
  collection,
  getDocs,
  query,
  where,
  orderBy,
  onSnapshot,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { format } from "date-fns";
import { Helmet } from "react-helmet";

import AgentView from "./agentView";
import SupplierView from "./supplierView";
import { MainContext, getDateAndTimeDiff, devConsolelog } from "../Utility";
import { REQ_EXPIRE_TIMEOUT_DAYS } from "../Constants";
import { db, auth } from "../firebaseConfig";

const ActiveReqs = () => {
  // const userData = JSON.parse(localStorage.getItem("user"));
  // const isUserAgent = Boolean((userData || {}).isAgent);
  const { userData, isUserAgent } = useContext(MainContext);
  const [reqs, setReqs] = useState([]);
  const [allReqs, setAllReqs] = useState([]);
  const [loading, setLoading] = useState(false);


  const dataStates = {
    reqs,
    setReqs,
    loading,
    allReqs,
  };

  // useEffect(() => {
  //   const fetchUserIdToken = async () => {
  //     try {
  //       auth.onAuthStateChanged(async (user) => {
  //         if (user) {
  //           // user.getIdToken().then(function(data) {
  //           //   console.log(data)
  //           // });
  //           let signedInIdToken = await auth.currentUser.getIdToken(
  //             /* forceRefresh */ true,
  //           );
  //           console.log("signedInIdToken ", signedInIdToken);
  //         }
  //       });
  //     } catch (e) {
  //       console.log("signedInIdToken error ", e);
  //     }
  //   };

  //   fetchUserIdToken();
  // }, []);

  const checkExpiredReqs = async (data = []) => {
    console.log("getDateAndTimeDiff checkExpiredReqs", data);
    let filteredReqs = [...data];
    data.forEach(async (item, i) => {
      let daysDiffVal = getDateAndTimeDiff(item.createdAt);
      // if (
      //   daysDiffVal.days < 0 &&
      //   Math.abs(daysDiffVal.days) > REQ_EXPIRE_TIMEOUT_DAYS
      // ) 

      if(item?.startDate < Date.now()) {
        const docRef = doc(db, "agentRequirements", item.id);
        await updateDoc(docRef, {
          status: "expired",
        });
        filteredReqs.splice(i, 1);
      }
    });
    // sort based on createdAt
    setReqs(
      filteredReqs.sort(
        (a, b) => Number(b.createdAt || 0) - Number(a.createdAt || 0),
      ),
    );
  };

  // const fetchAgentStatistics = async (agentId) => {
  //   try {
  //     const docRef = doc(db, "userStatistics", agentId);
  //     const docSnap = await getDoc(docRef);

  //     if (docSnap.exists()) {
  //       const data = docSnap.data();
  //       const totalBookings = data?.totalBookings || '';
  //       const overallRating = data?.ratings?.overallRating || '';
  //       return { totalBookings, overallRating };
  //     } else {
  //       return { totalBookings: '', overallRating: '' };
  //     }
  //   } catch (error) {
  //     console.error("Error fetching agent statistics:", error);
  //     return { totalBookings: 0, overallRating: 0 };
  //   }
  // };

  const fetchReqsWithBids = async (phone) => {
    try {
      const docRef = doc(db, "suppBidsPostedHistory", phone);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        const reqsWithBids = data?.reqsWithBids || [];
        return reqsWithBids;
      } else {
        console.warn("No such document in suppBidsPostedHistory!");
        return [];
      }
    } catch (error) {
      console.error("Error fetching reqsWithBids:", error);
      return [];
    }
  };

  const compFuncs = {
    getAgentActiveReqs: async () => {
      setLoading(true);
      const q = query(
        collection(db, "agentRequirements"),
        where("status", "==", "active"),
        where("agentId", "==", userData.phone)
        // orderBy("createdAt", "desc")
      );

      const unsubscribe = await onSnapshot(
        q,
        async (snapshot) => {
          // if (!snapshot.exists()) return;
          let data = snapshot.docs;
          console.log("snapshot", data, snapshot);
          data = await Promise.all(
            data.map(async (i) => {
              // const req = { ...i.data(), id: i.id };
              // const { totalBookings, overallRating } = await fetchAgentStatistics(req.agentId);
              // return { ...req, totalBookings, overallRating };
              return { ...i.data(), id: i.id };
            })
          );

          setReqs(
            data.sort(
              (a, b) => Number(b.createdAt || 0) - Number(a.createdAt || 0)
            )
          );

          checkExpiredReqs(data);
        },
        (error) => {
          console.log("view req error", error);
        }
      );

      return unsubscribe;
    },

    getAllActiveReqs: async () => {
      setLoading(true);
      // console.log('get supp reqs ', userData.destinations)
      let q = query(
        collection(db, "agentRequirements"),
        where("status", "==", "active")
      );

      if (userData?.destinations && userData?.destinations.length > 0) {
        q = query(
          collection(db, "agentRequirements"),
          where("status", "==", "active"),
          where("destination", "in", userData?.destinations)
          // orderBy("createdAt", "desc")
        );
      }

      const unsubscribe = onSnapshot(
        q,
        async (snapshot) => {
          let data = snapshot.docs;
          // if (!snapshot.exists()) return;
          data = await Promise.all(
            data.map(async (i) => {
              const req = { ...i.data(), id: i.id };
              return { ...req };
            })
          );

          // Sort the data by createdAt
          setReqs(
            data.sort(
              (a, b) => Number(b.createdAt || 0) - Number(a.createdAt || 0)
            )
          );

          checkExpiredReqs(data);
        },
        (error) => {
          console.log("view req error", error);
        }
      );

      return unsubscribe;
    },

    getSuppAllReqs: async (chunkIndex = 1) => {
      const chunkSize = 10; 
      setLoading(true);

      try {
        const reqsWithBids = await fetchReqsWithBids(userData.phone);
        const reqIds = [...new Set(reqsWithBids.map((item) => item?.reqId))]; 
        console.log("getSuppAllReqs reqIds", reqIds, reqIds.length === 0, chunkIndex)

        if (reqIds.length === 0) {
          setAllReqs([]);
          setLoading(false);
          return;
        }
        const startIdx = reqIds.length - chunkSize * (chunkIndex - 1) - 1;
        const endIdx = Math.max(reqIds.length - chunkSize * chunkIndex, 0);
        console.log("getSuppAllReqs start end index", startIdx, endIdx);
        if (startIdx < 0) {
          setLoading(false);
          return;
        }
        const allReqsChunk = [];
        for (let i = startIdx; i >= endIdx; i--) {
          const reqId = reqIds[i];
          try {
            const docRef = doc(db, "agentRequirements", reqId);
            const docSnap = await getDoc(docRef);
            console.log("getSuppAllReqs req data", reqId, docSnap.exists(), docSnap.data())
            if (docSnap.exists()) {
              const req = docSnap.data();
              req.id = docSnap.id;

              if (!req.startDate || isNaN(new Date(req.startDate).getTime())) {
                continue;
              }
              console.log("getSuppAllReqs dt_CHEC:", req?.trackingId);
              allReqsChunk.push(req);
            }
          } catch (error) {
            console.error(`Error fetching request with ID ${reqId}:`, error);
          }
        }

        allReqsChunk.sort((a, b) => Number(b.createdAt || 0) - Number(a.createdAt || 0));

        setAllReqs((prev) => {
          const updated = [...prev];
          updated[chunkIndex - 1] = allReqsChunk; 
          return updated;
        });
      } catch (error) {
        console.error("Error in getSuppAllReqs:", error);
      }

      setLoading(false);
    }
  };

  console.log("view reqs render ", dataStates);
  console.log("REQ_DATA", reqs);
  return (
    <>
      <Helmet>
        <title>CabEasy Active Requirements: Track your active requirements</title>
        <link rel="canonical" href="https://cabeasy.in/my-reqs" />
      </Helmet>

      {isUserAgent ? (
        <AgentView
          userData={userData}
          {...dataStates}
          {...compFuncs}
          setLoading={setLoading}
        />
      ) : (
        <SupplierView
          userData={userData}
          {...dataStates}
          {...compFuncs}
          setLoading={setLoading}
        />
      )}
    </>
  );
};

export default ActiveReqs;
