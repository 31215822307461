import NavigationIcon from "@mui/icons-material/Navigation";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import NoRecordsFound from "../Commons/NoRecordsFound.js";
import { Link, useNavigate } from "react-router-dom";

import DestReqCardIndex from "../Commons/destReqCardIndex";
import AppLoader from "../layout/Loading";
import AppSubHeader from "../Commons/appSubHeader";

const AgentView = ({
  userData,
  reqs,
  setReqs,
  loading,
  getAgentActiveReqs,
  setLoading
}) => {
  const [filteredReqs, setFilteredReqs] = useState(reqs);
  const navigate = useNavigate();

  useEffect(() => {
    let unsubscribe = () => {};
    const getReqs = async () => {
      unsubscribe = await getAgentActiveReqs();
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    };
    getReqs();

    return unsubscribe();
  }, []);

  useEffect(() => {
    setFilteredReqs(reqs);
  }, [reqs]);

  console.log(reqs, "req");

  return (
    <>
      {reqs.length >0 && <AppSubHeader name="Your Active Requirements" />}
      {loading && <AppLoader />}
      <Grid
        container
        spacing={{ xs: 2, md: 2 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {reqs.length <= 0 && !loading && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto"
            }}
          >
            <NoRecordsFound 
              body={<>
                <Typography variant="body1" sx={{ textAlign: "center", width: "100%", marginTop: 2 }}>
                Post Your First Request
                </Typography>
                <Typography variant="body2" color="primary" sx={{ textAlign: "center", width: "100%" }}>
                No Commision. &nbsp;Multiple B2B Bids.
                </Typography>
                {/* <Typography variant="body2" color="primary" sx={{ textAlign: "center", width: "100%" }}>
                Multiple B2b Bids.
                </Typography> */}
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    marginTop: "10px"
                  }}
                  onClick={() => navigate("/post-req")}
                >
                  Click Here
                </Button>
              </>} 
            />

            {/* <Typography variant="h6" sx={{ textAlign: "center", marginTop : 3, width: "100%" }}>
              Post Your First Request
            </Typography>
            <Button
              variant="contained"
              sx={{
                marginTop: "10px"
              }}
              onClick={() => (window.location.href = "/post-req")}
            >
              Click Here
            </Button> */}
          </Box>
        )}

        {filteredReqs.map((req) => {
          let { id = "" } = req;
          return (
            <Grid item xs={12} sm={4} md={6} key={req?.id}>
              <Link to={`/req/view-bids/${id}`} state={{ req }}>
                <DestReqCardIndex reqData={req} itemId={id} />
              </Link>
            </Grid>
          );
        })}
      </Grid>

      <Fab
        variant="extended"
        color="primary"
        href={"/post-req"}
        sx={{ position: "fixed", bottom: 70, right: 20 }}
      >
        <NavigationIcon sx={{ mr: 1 }} />
        Post Req
      </Fab>
    </>
  );
};

export default AgentView;
